import { Params } from 'react-router-dom';
import { Observable, of as observableOf, switchMap } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { RankedColumn } from 'state-domains/domain/drillhole';
import { buildLoadRankedColumns, ParamObject } from 'state-domains/network/urls';
import { sendRetrieveRequest } from 'state-domains/utils/network';
import { convertToCamel } from 'state-domains/utils/typeCast';

export const loadRankedColumnsByProject = (
    actionName: string,
    processResultFunction: Function,
    _paramObject?: ParamObject,
    params?: Params<string>,
): Observable<{
    result: RankedColumn[];
    actionName: string;
    processResultFunction: Function;
}> => {
    const url = buildLoadRankedColumns(`projects/${params?.projectId ?? ''}`, '');
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const convertedRankedColumns = convertToCamel<RankedColumn[]>(response.ranked_columns);
            return observableOf({
                result: convertedRankedColumns,
                actionName,
                processResultFunction,
            });
        }),
    );
};
